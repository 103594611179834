.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&family=Lato:wght@400;700&display=swap");

@font-face {
  font-family: "halis-medium";
  /* src: url('../../public/fonts/Halis-font/Ahmet\ Altun\ -\ HalisGR-Medium.otf'); */
  src: url("../public/fonts/Halis-font/Ahmet\ Altun\ -\ HalisGR-Regular.otf");
}

.dashboard-graph {
  background-color: white;
  padding: 1%;
  border-radius: 4px;
  margin-left: 1%;
  margin-right: 1%;
  box-shadow: 1px 1px 5px 1px #bfbfbf;
}

.family-jost {
  font-family: Jost !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}

/*  Font Size   */
.font-size-15 {
  font-size: 15px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.font-size-30 {
  font-size: 30px !important;
}
.font-size-40 {
  font-size: 40px !important;
}
.font-size-50 {
  font-size: 50px !important;
}
.font-size-60 {
  font-size: 60px !important;
}
.max-width-85 {
  max-width: 85px !important;
}
.max-width-120 {
  max-width: 120px !important;
}

.pointer {
  cursor: pointer !important;
}

.max-width-200 {
  max-width: 200px !important;
}
.max-width-100 {
  max-width: 100px !important;
}
.max-width-600 {
  max-width: 600px !important;
}

.text-decoration-none {
  text-decoration: none !important;
}
.text-align-justify {
  text-align: justify !important;
  text-justify: inter-word !important;
}
.heading-color-343434ed {
  color: #343434ed !important;
}

/*  Full Button   */
.full-button {
  /* background-image: linear-gradient(lightgreen,green); */
  background-color: #a8c670 !important;
  border: 0 !important;
  border-radius: 12px !important;
}
.full-button:hover {
  /* background-image: linear-gradient(rgb(0, 227, 0),green); */
  background-color: #a8c670 !important;
  box-shadow: 1px 1px 3px 1px rgb(131, 131, 131) !important;
  border: 0 !important;
}

input[type="email"]:focus {
  border: 2px solid #323c2bb2;
  outline: none;
  background-color: #f6f6f6 !important;
}

input[type="password"]:focus {
  border: 2px solid #323c2bb2;
  outline: none;
  background-color: #f6f6f6 !important;
}
.textarea.form-control{
  border-radius: 12px !important;
}