.image-logo-login {
  width: 40%;
}

/*  Sidebar design  */
.css-16jkw2k > .ps-menu-button {
}
.css-16jkw2k > .ps-menu-button:hover {
  background-color: transparent !important;
}

@media only screen and (min-width: 992px) {
  .sidebar-layout {
    display: flex;
  }
  .navbar-main {
    background-color: #ffffff;
    box-shadow: 0px 1px 35px rgba(220, 224, 249, 0.43);
    width: 18%;
    height: 100vh;
  }
  .nav-links {
    text-align: start;
    text-decoration: none;
    color: black;
    font-family: lato;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    /* margin-left: 5%; */
  }
  .nav-logo {
    width: 70%;
  }
}

@media only screen and (max-width: 992px) {
  .sidebar-layout {
    display: block;
  }
  .navbar-main {
    background-color: #ffffff;
    box-shadow: 0px 1px 35px rgba(220, 224, 249, 0.43);
    width: 100%;
  }
  .nav-links {
    text-align: start;
    text-decoration: none;
    color: black;
    font-family: lato;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-left: 3%;
  }
  .nav-logo {
    display: none;
  }
}

.heading {
  font-size: 14px;
}
.text {
  font-size: 14px;
}
.activePage {
  background-color: rgb(128, 128, 128);
  color: white;
  font-weight: 500;
}

/* .image {
  object-fit: contain;
  height: 150px;
  width: 85%;
} */

/* .image:hover{
    opacity: 0.2;
} */

.button-image {
  /* position: absolute; 
    left: 0; 
    right: 0; */
  /* margin-left: auto;  */
  /* margin-right: auto; */
  /* width: 100px;  Need a specific value to work */
}

/*  Login Style   */
/* .image {
  object-fit: cover;
  width: 50% !important;
  position: relative !important;
  height: unset !important;
  opacity: 1;
} */

.login-image-section {
  /* background-color: red; */
  /* height: 100vh; */
  /* background-image: url("/public/images/banana"); */
}
.input-field {
  display: block;
  width: 100%;
  padding: 0.375rem 0.95rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #323c2b12;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-image-section {
  position: relative;
  z-index: 1;
}

.form-main {
  padding: 90px;
}

/*    sidebar style   */
@media only screen and (min-width: 992px) {
  .sidebar-layout {
    display: flex;
  }
  .navbar-main {
    background-color: #ffffff;
    box-shadow: 0px 1px 35px rgba(220, 224, 249, 0.43);
    width: 18%;
    height: 100vh;
  }
  .nav-links {
    text-align: start;
    text-decoration: none;
    color: #434343;
    font-family: Jost;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding-left: 5%;
    padding-top: 3%;
    /* padding-bottom: 1.5%; */
    padding-bottom: 3%;
  }

  .nav-logo {
    width: 70%;
  }
}

@media only screen and (max-width: 992px) {
  .sidebar-layout {
    display: block;
  }
  .navbar-main {
    background-color: #ffffff;
    box-shadow: 0px 1px 35px rgba(220, 224, 249, 0.43);
    width: 100%;
  }
  .nav-links {
    text-align: start;
    text-decoration: none;
    color: black;
    font-family: Jost;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding-left: 5%;
    padding-top: 3%;
    padding-bottom: 1.5%;
  }
  .nav-logo {
    display: none;
  }
}

.nav-item:hover,
.nav-item .nav-links:hover {
  color: #b1b2b2;
}
.nav-link-icon {
  font-size: 24px;
}

.heading {
  font-size: 14px;
}
.text {
  font-size: 14px;
}
.activePage {
  /* background-color: #91c930; */
  background-color: #a8c670;
  color: white;
  font-weight: 500;
}

/* .image {
  object-fit: contain;
  height: 150px;
  width: 85%;
} */

/* .image:hover{
      opacity: 0.2;
  } */

.button-image {
  /* position: absolute; 
      left: 0; 
      right: 0; */
  /* margin-left: auto;  */
  /* margin-right: auto; */
  /* width: 100px;  Need a specific value to work */
}

/* .image {
  object-fit: cover;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
} */

.nav-links.active {
  text-decoration: none;
  color: white;
  /* background-color: #91c930; */
  background-color: #a8c670;
  border-radius: 4px;
  font-weight: 700;
}

/*  Signup  */
/* .image {
  object-fit: cover;
  width: 50% !important;
  position: relative !important;
  height: unset !important;
  opacity: 1;
} */

.form-main {
  padding: 90px;
}

@media screen and (min-width: 992px) {
  .subheader {
    display: flex;
  }
}

@media screen and (max-width: 992px) {
  .subheader {
    display: none !important;
  }
}
