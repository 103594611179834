/*    Categories  */
.users-addbutton {
    width: 100%;
    background-color: #91c930 !important;
    border: 0;
  }
  
  .users-addbutton:hover {
    width: 100%;
    background-color: #8db941;
    border: 1;
  }
  
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }
  
  .search-bar {
    background-color: #e5e5e5;
    padding: 2%;
    border-radius: 6px;
  }
  .search-field {
    border: 0;
    background-color: transparent;
    width: 90%;
  }
  
  .search-date {
    background-color: #e5e5e5;
    padding: 2%;
    border-radius: 6px;
  }
  .date-picker{
    background-color: #e5e5e5;
    padding: 2%;
    border: 0;
    border-radius: 6px;
    width: 100%;
  }
  
  .search-date-input {
    border: 0;
    background-color: transparent;
    width: 100%;
  }
  .dropdown-toggle {
    background-color: #e5e5e5 !important;
    border: 0 !important;
    color: #343434 !important;
  }
  /* .search-dropdown{
      background-color: #dbdbdb;
      color: black;
  }
  .dropdown-toggle .btn .btn-primary{
  background-color: red !important;
  } */
  .heading {
    font-size: 15px;
    color: rgb(96, 96, 96);
    font-family: "Jost";
    /* font-weight: 600; */
  }
  
  .pagination-button {
    color: white;
    cursor: pointer;
  
    background-color: #a8c670;
  }
  
  .pagination-button:hover {
    color: white;
    cursor: pointer;
    background-color: #8db941;
  }
  .image {
    object-fit: cover;
    width: 100% !important;
    position: relative !important;
    height: unset;
  }
  
  /*    Add Category    */
  .input-field{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}