.dashboard-card-1{
    /* background-image: linear-gradient(90deg, #a8c670, #a8c670a6); */
    background-color: #91a472;
}
.dashboard-card-2{
    /* background-image: linear-gradient(90deg, #a8c670, #a8c670a6); */
    background-color: #a6c76c;
}
.dashboard-card-3{
    /* background-image: linear-gradient(90deg, #a8c670a6, #a8c670); */
    background-color: #5f6c49;
}